import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import "./Header.css"

const Header = ({ siteTitle }) => (
  <header>
    <div className="wrapper">
      <div className="logo">
        <Link to="/">
          <img src="/icon.png" alt="logo" className="header-image" />
          <span className="header-title">{siteTitle}</span>
        </Link>
      </div>

      <div className="header-item-1-col">
        <Link to="/">
          <span className="header-item">Блог</span>
        </Link>
      </div>

      <div className="header-item-2-col">
        <Link to="/">
          <span className="header-item ">Уроки</span>
        </Link>
      </div>

      <div className="header-item-3-col">
        <Link to="/">
          <span className="header-item ">Донат</span>
        </Link>
      </div>

      <div className="header-item-12-col">
        <input type="search" id="search" placeholder="Поиск" />
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
